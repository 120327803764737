<template>
    <section v-loading="loading" class="deuda-ver">
        <div class="row mx-0">
            <div class="col px-0 pt-4">
                <!-- Info -->
                <div class="row mx-0">
                    <div class="col-12">
                        <div class="row mx-0 align-items-center">
                            <div class="rounded-circle bg-general3 cr-pointer d-middle-center" style="width:20px;height:20px;" @click="$router.back();">
                                <i class="icon-left text-white" />
                            </div>
                            <img :src="datos.logo_mini_firmado" width="35" height="35" class="obj-cover border rounded-circle ml-2" />
                            <div class="col">
                                <p class="text-general f-17 f-600">{{ datos.nombre }}</p>
                            </div>
                            <!--  <div class="bg-general ml-auto cr-pointer px-3 text-white d-middle-center shadow br-8" style="height:32px;" @click="entregarDinero">
                                Registrar pago
                            </div>
                            <div class="bg-white ml-2 cr-pointer px-3 text-general ml-2 d-middle-center border shadow br-8" style="height:32px;" @click="anularCredito">
                                Anular crédito
                            </div> -->
                        </div>
                    </div>
                    <div class="col-12 px-0 py-3">
                        <div class="row mx-0 align-items-center py-2 mx-2">
                            <div class="col-auto pr-2">
                                <i class="icon-pedidos f-20 text-general" />
                            </div>
                            <div class="col-3 px-2 f-600 pt-1 text-general">
                                <p>
                                    Pedido No
                                    {{ datos.id_pedido }}
                                </p>
                            </div>
                            <div class="col-auto text-general f-15">
                                <p>
                                    Fecha:
                                    <b>
                                        {{ formatearFecha(datos.created_at_pedido) }} <!-- <br /> {{ formatearFecha(datos.created_at_pedido, 'hh:mm a') }} -->
                                    </b>
                                </p>
                            </div>
                            <div class="col-auto text-general f-15">
                                <p>
                                    Productos
                                    <b>{{ datos.productos_count }}</b>
                                </p>
                            </div>
                            <div class="col-auto">
                                <router-link class="text-general" style="text-decoration:underline;" :to="{ name: 'tendero.pedidos', params: { id_pedido: datos.id_pedido } }" target="_blank">
                                    Ir al pedido
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 py-2" />
                <!-- Cuotas y historial -->
                <div class="row mx-0">
                    <div class="col d-flex py-2 text-center border text-general f-17">
                        Valor total del pedido: <b>{{ separadorNumero(datos.valor_final) }}</b>
                        <span class="mr-1 ml-auto">Cuotas: <b class="text-general">{{ datos.creditos && datos.creditos.length || 0 }}</b></span>
                    </div>
                    <div class="col d-flex py-2 text-center border text-general">
                        <span class="mr-4">Pagó: <b>{{ separadorNumero(datos.total_pagado) }}</b></span>
                        <span class="mr-2">Debe: <b class="text-general-red">{{ separadorNumero(datos.total_debe) }}</b></span>
                        <span class="mr-1 ml-auto">Pagos: <b class="text-general">{{ datos.historial && datos.historial.length || 0 }}</b></span>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col custom-scroll overflow-auto border-right border-left pt-3" style="height:calc(100vh - 330px);">
                        <!-- <div class="row mx-0 text-general2 px-2 bg-light-f5 py-1">
                            <div class="col-auto">
                                Cuotas establecidas
                            </div>
                            <div class="col-auto ml-auto">
                                {{ datos.creditos && datos.creditos.length || 0 }}
                            </div>
                        </div> -->
                        <div class="row mx-0 pt-3 justify-center">
                            <div v-for="(cuota, c) in datos.creditos" :key="c" class="col-10 px-0 py-2 border br-8 mb-3">
                                <div class="row mx-0">
                                    <p class="col text-general f-16 f-600">{{ separadorNumero(cuota.valor) }}</p>
                                    <p class="col pl-0 text-right text-general f-15">
                                        <span v-text="textosCuotas(cuota)" />
                                        <i :class="`f-18 ${iconoCuotas(cuota)}`" />
                                    </p>
                                    <div class="col-12 text-general f-15 d-flex">
                                        {{ formatearFecha(cuota.fecha) }}
                                        <span v-if="isParcial(cuota)" class="text-right ml-auto"> {{ separadorNumero(cuota.valor_pendiente) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col custom-scroll overflow-auto border-left border-right pt-3" style="height:calc(100vh - 330px);">
                        <!-- <div class="row mx-0 text-general2 px-2 bg-light-f5 py-1">
                            <div class="col-auto">
                                Historial de pagos
                            </div>
                            <div class="col-auto ml-auto">
                                {{ datos.historial && datos.historial.length || 0 }}
                            </div>
                        </div> -->
                        <div class="row mx-0 justify-center pt-3">
                            <div v-for="(historial, h) in datos.historial" :key="h" class="col-10 px-0 py-2 border br-8 mb-3 cr-pointer" @click="detallePago(historial)">
                                <div class="row mx-0">
                                    <div class="col-auto pr-1">
                                        <img :src="imagenHistorial(historial)" alt="" />
                                    </div>
                                    <div class="col px-0 pt-1">
                                        <div class="row mx-0">
                                            <p class="col text-general tres-puntos f-16 f-500">
                                                {{ separadorNumero(historial.valor) }}
                                            </p>
                                            <div class="col d-flex justify-content-end">
                                                <div :class="`px-3 br-20 ${historial.estado == 1 || historial.estado == 0 ? 'aprobado' : historial.estado == 2 ? 'pendiente' : 'cancelado'}`">
                                                    {{ historial.estado == 1 || historial.estado == 0 ? 'Aprobado' : historial.estado == 2 ? 'Pendiente' : 'Cancelado' }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mx-0 my-2">
                                            <p class="col text-general2 f-15">
                                                {{ nombreHistorial(historial) }}
                                            </p>
                                            <p v-if="historial.ref" class="col text-right tres-puntos text-general">
                                                Ref: {{ historial.x_payus && historial.x_payus.transaction_id || historial.id }}
                                            </p>
                                        </div>
                                        <div class="row mx-0">
                                            <p class="col-12 text-general2 f-15">
                                                {{ formatearFecha(historial.created_at) }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 border-left">
                Aqui va el chat
            </div>
        </div>
        <!-- Partials -->
        <modal-detalle-pago ref="modalDetallePago" />
        <modal-registrar-pago ref="modalRegistrarPago" />
        <modal ref="modalAnularCredito" titulo="Anular crédito" no-aceptar adicional="Anular">
            <div class="row mx-0 justify-center">
                <div class="col-auto text-general f-15">
                    ¿Desea anular el crédito de este pedido? <br /> Se cancelarán las cuotas pendientes
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import creditos from '~/services/creditos/creditos'
import moment from 'moment'
export default {
    name: 'DeudaVer',
    components: {
        modalDetallePago: () => import('~/pages/almacen/tendero/partials/modalDetallePago.vue'),
        modalRegistrarPago: () => import('./partials/modalRegistarPago')
    },
    data(){
        return{
            datos: {},
            loading: false
        }
    },
    async mounted(){
        await this.detalleLeechero()
    },
    methods: {
        imgHistorial(estado){
            switch (estado){
            case 1:
                return '/img/icons/pago_efectivo.svg';
            case 2:
                return '/img/icons/pago_online.svg';
            case 3:
                return '/img/icons/pago_online.svg';
            }
        },
        iconoCuotasEstablecidas(estado){
            switch (estado){
            case 1:
                return 'icon-ok-circled-outline text-success';
            case 2:
                return 'icon-alert-triangle text-warning';
            case 3:
                return 'icon-attention-alt text-general'; 
            case 4:
                return 'icon-attention-alt text-general-red';
            }
        },
        textosCuota(estado){
            switch (estado){
            case 1:
                return 'Pagada';
            case 2:
                return 'Pago parcial';
            case 3:
                return 'Cuota vencida';
            case 4:
                return 'Próxima cuota';
            }
        },
        iconoCuotas(cuota){
            if (cuota.valor_pendiente == 0){
                return this.iconoCuotasEstablecidas(1)
            }
            if(cuota.valor_pendiente == cuota.valor){
                if (moment(cuota.fecha).isBefore(moment().format('YYYY-MM-DD'))){
                    return this.iconoCuotasEstablecidas(3)

                } else {
                    return this.iconoCuotasEstablecidas(4)

                }
            }
            if(cuota.valor_pendiente != cuota.valor){
                return this.iconoCuotasEstablecidas(2)
            }
            
        },
        detallePago(pago){
            this.$refs.modalDetallePago.toggle(pago);
        },
        isParcial: (cuota) =>  cuota.valor_pendiente != 0 && (cuota.valor_pendiente != cuota.valor),
        textosCuotas(cuota){
            if (cuota.valor_pendiente == 0){
                return this.textosCuota(1)
            }
            if(cuota.valor_pendiente == cuota.valor){
                if (moment(cuota.fecha).isBefore(moment().format('YYYY-MM-DD'))){
                    return this.textosCuota(3)

                } else {
                    return this.textosCuota(4)

                }
            }
            if(cuota.valor_pendiente != cuota.valor){
                return this.textosCuota(2)
            }
        },
        async detalleLeechero(){
            try {
                this.loading = true
                const { id_pedido: pedido } = this.$route.params
                const params = {
                    pedido
                }
                const { data } = await creditos.detalleLeechero(params)
                this.datos = data
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        entregarDinero(){
            this.$refs.modalRegistrarPago.toggle();
        },
        anularCredito(){
            this.$refs.modalAnularCredito.toggle();
        },
        imagenHistorial(historial){
            if(historial.estado == 0 && historial.tipo_efectivo == null) return '/img/icons/pago_efectivo.svg';
            if(historial.estado == 0 && historial.tipo_efectivo == 1) return '/img/icons/pago_efectivo.svg';
            if(historial.estado == 0 && historial.tipo_efectivo == 2) return '/img/icons/transferencia.svg';
            if(historial.estado != 0 && historial.tipo_efectivo == null) return '/img/icons/transferencia.svg';
            if(historial.estado == 0 && historial.tipo_efectivo == 3) return '/img/icons/pago_efectivo.svg';
            return '/img/icons/pago_online.svg';
        },
        nombreHistorial(historial){
            if(historial.estado == 0 && historial.tipo_efectivo == null) return 'Efectivo';
            if(historial.estado == 0 && historial.tipo_efectivo == 1) return 'Efectivo';
            if(historial.estado == 0 && historial.tipo_efectivo == 2) return 'Transferencia';
            if(historial.estado != 0 && historial.tipo_efectivo == null) return 'Transferencia';
            if(historial.estado == 0 && historial.tipo_efectivo == 3) return 'Por comisión'
            return 'En línea'
        },
    }
}
</script>
<style lang="css" scoped>
.aprobado{
    background-color: #27D07B;
    color: #ffffff;
}
.pendiente{
    background-color: #F5F5F5;
    color: #707070;
}
.cancelado{
    background-color: #FF3B63;
    color: #ffffff;
}
</style>